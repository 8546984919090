export class AlertSlide {
  constructor() {
    this.alertID = "#alertSlide";
    this.dispMilliSec = 3200;
    this.timer1 = null;
    this.timer2 = null;
    this.timer3 = null;
    this.timer4 = null;
  }
  addEvent() {
    // 閉じる
    $(document).on("click", ".alert-slide > div", (e) => {
      this.close(e.target);
    });
    // クリックから実行する場合
    $(document).on("click", "[data-toggle='alert-slide']", (e) => {
      this.callByClick(e.target);
    });
  }
  callByFunction(message, type = "alert") {
    this.show(message, type);
  }
  callByClick(el) {
    const $this = $(el);
    const message = $this.data("message");
    let type = $this.data("type");
    if (!type) {
      type = "alert";
    }
    this.show(message, type);
  }
  getAlert(message) {
    let $alert = $(this.alertID);

    if (!$alert[0]) {
      $("body").append(
        "<div id='alertSlide' class='alert-slide'><p>" +
          message +
          "</p><div><span></span></div></div>",
      );
      $alert = $(this.alertID);
    } else {
      $alert.children("p").text(message);
    }
    return $alert;
  }
  isActive() {
    const $alert = this.getAlert();
    if ($alert.hasClass("is-active")) {
      return true;
    } else {
      return false;
    }
  }
  setActive() {
    const $alert = this.getAlert();
    $alert.addClass("is-active");
  }
  setInactive() {
    const $alert = this.getAlert();
    $alert.removeClass("is-active");
    $alert.remove();
  }
  blink() {
    const $alert = this.getAlert();
    // opacityはtransitionがかかってしまうので、hide/show
    $alert.hide();
    setTimeout(function () {
      $alert.show();
    }, 10);
  }
  show(message, type = "alert") {
    const $alert = this.getAlert(message);
    if (type === "notice") {
      $alert.addClass("style-notice");
    } else {
      $alert.removeClass("style-notice");
    }

    if (this.isActive()) {
      this.blink();
      clearTimeout(this.timer1);
      clearTimeout(this.timer2);
      clearTimeout(this.timer3);
    }

    // 出現開始
    this.timer1 = setTimeout(() => {
      this.setActive();
      $alert.addClass("style-active");
    }, 10);

    // 消す開始
    this.timer2 = setTimeout(() => {
      $alert.removeClass("style-active");
    }, this.dispMilliSec);

    // 消す完了
    this.timer3 = setTimeout(() => {
      this.setInactive();
    }, this.dispMilliSec + 200);
  }
  close() {
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
    clearTimeout(this.timer3);
    this.setInactive();
  }
}
